*,
*::before,
*::after {
    box-sizing: border-box;
}

/* body {
    margin: 0;
    padding: 0;
    font-family: "Corbel V2";
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    box-sizing: border-box;
} */

p {
    margin: 0 0 20px;
}

.main-wrapper {
    background-color: rgb(55 101 127 / 20%);
    padding: 38px;
    min-height: 100vh;
}

.signin-form-wrapper {
    display: flex;
    max-width: 1200px;
    margin: auto;
    background-color: #fff;
    border-radius: 20px;
}
.main-wrapper .ar-row-reverse{
    flex-direction: row-reverse;
}
/* .col-6 {
    width: 100%;
    max-width: 50%;
} */

/* .form-image {
    text-align: center;
    background: #37657F;
    height: 100%;
}

.login-image {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
} */
/* 
.form-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.login-form-wrapper {
    position: relative;
    max-width: 460px;
    margin: 0 auto;
    width: 100%;
}

.close-btn{
    position: absolute;
    right: 40px;
    top: 40px;
    background: rgba(217, 217, 217, 0.50);
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.signin-form-wrapper .signup-item.col-6 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.block-heading h4 {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 8px;
}

.role-wrapper {
    border-radius: 5px;
    /* padding: 10px; */
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    cursor: pointer;
}

.role-wrapper.active {
    border: 1px solid #0F172A;
}

.role-image {
    padding: 25px 30px;
}

.role-content {
    padding: 0 16px 16px;
} 

/* .role-image img {
    border-radius: 5px;
    max-width: 118px;
    width: 100%;
    min-height: 90px;
    object-fit: cover;
} */

.d-flex {
    display: flex;
    gap: 15px;
}

.mb-4 {
    padding-bottom: 2rem;
}

.role-content p {
    margin: 0;
    color: #37657F;;
}

.role-content p:last-child {
    color: #0F172A;
}

.btn {
    width: 100%;
    background: #37657F;
    padding: 7px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #37657F;
    font-size: 14px;
    font-weight: 600;
    font-family: "Corbel V2";
    letter-spacing: 0.5px;
    cursor: pointer;
    min-height: 50px;
}

@media screen and (max-width: 1199px) {
    .login-form-wrapper {
        max-width: 300px;
    }
}


@media screen and (max-width:991px) {

    .role-image img {
        max-width: 100%;
    }

    .login-form-wrapper {
        padding: 40px 0;
    }

}

@media screen and (max-width:767px) {
    .signin-form-wrapper {
        display: block;
    }

    /* .col-6 {
        max-width: 100%;
    } */


    .student-role .col-6 {
        max-width: 100%;
    }

    .role-image img {
        max-width: 100%;
    }

}


@media screen and (max-width:575px) {
    .d-flex {
        display: block;
    }

    .login-form-wrapper {
        max-width: 240px;
    }

    .role-wrapper {
        text-align: center;
    }
}