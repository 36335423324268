*,
*::before,
*::after {
    box-sizing: border-box;
}
/* 
body {
    margin: 0;
    padding: 0;
    font-family: "Corbel V2";
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    box-sizing: border-box;
} */

p {
    margin: 0 0 20px;
}

.main-wrapper {
    background-color: rgb(55 101 127 / 20%);
    padding: 48px;
}

.signin-form-wrapper {
    display: flex;
    max-width: 800px;
    margin: auto;
    background-color: #fff;
    border-radius: 20px;
}
.main-wrapper .ar-row-reverse{
    flex-direction: row-reverse;
}

.align-center {
    align-items: center;
}

/* .col-6 {
    width: 100%;
    max-width: 50%;
} */

/* .form-image {
    text-align: center;
    background: #37657F;
    height: 100%;
    border-radius: 20px 0 0 20px;
}

.login-image {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
} */

/* .form-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.login-form-wrapper {
    /* padding: 40px; */
    position: relative;
}

/* .close-btn {
    position: absolute;
    right: 30px;
    top: -50px;
    background: rgba(217, 217, 217, 0.50);
    display: block;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.close-btn.t-40 {
    top: -115px;
}

.block-heading h4 {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 8px;
}

.block-content {
    color: #6c7487;
}

.form-group {
    display: block;
}

/* .form-group:not(:last-child) {
    margin-bottom: 15px;
} */
/* 
label {
    display: block;
    margin-bottom: 7px;
    color: #334155;
    font-size: 16px;
    font-weight: 400;
} */

/* input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(148, 163, 184, 0.5);
    color: #6c7487;
    display: block;
    width: 100%;
    background-clip: padding-box;
}

input::placeholder {
    color: #6c7487;
}

input:focus-visible {
    box-shadow: none;
    outline: none;
} */

.mt-35 {
    margin-top: 35px;
}

.forget-block .mt-35 {
    margin-top: 10px;
}

.forget-block .block-heading {
    padding: 17px 0;
}

/* .btn {
    width: 100%;
    background: #6c7487;
    border: none;
    padding: 7px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #6c7487;
    font-size: 14px;
    font-weight: 600;
    font-family: "Corbel V2";
    letter-spacing: 0.5px;
    cursor: pointer;
} */

.block-content .send-span {
    padding: 40px 0 25px;
}

button:disabled {
    /* background: #37657F; */
    opacity: 0.7;
    cursor: not-allowed;
}

/* .button-outline {
    background-color: #F1F5F9;
    border: 1px solid #37657F;
    color: #37657F;
} */

/* span.or {
    padding: 10px 0;
    display: block;
    text-align: center;
} */

.forget-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.otp-block input:focus {
    border: 1px solid #37657F !important;
}

/* =========== Reset Passwod ========= */

.verification-code {
    height: 70px;
    border: 1px solid #94a3b88f;
    border-radius: 12px;
}

/* .block-content a {
    color: #000;
    font-weight: 500;
} */

.block-content span {
    display: block;
    padding-bottom: 10px;
    color: rgba(0, 0, 0, 0.60);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.block-content span a {
    margin-left: 5px;
    color: #37657F;
}
/* 
.close-btn.t-30 {
    top: -30px;
} */


/* ============= New Password ============== */

.close-btn.tp-20 {
    top: 20px;
}

/* ============ Change Password =========== */

.set-image {
    background: #F1F5F9;
    display: inline-flex;
    border-radius: 20px;
    margin-bottom: 30px;
    width: 160px;
    height: 160px;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.set-image img {
    width: 90px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
}

.all-set-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

/* .all-set-block .close-btn {
    top: 0;
} */

@media screen and (max-width:991px) {
    .close-btn {
        top: 0px;
    }

    p {
        margin: 0 0 15px;
    }

    .close-btn.t-30 {
        top: 10px;
    }

    .all-set-block .close-btn {
        top: 20px;
    }

}

@media screen and (max-width:767px) {
    .signin-form-wrapper {
        display: block;
    }

    /* .col-6 {
        max-width: 100%;
    } */

    .close-btn {
        top: 20px;
    }

    .form-image {
        border-radius: 20px 20px 0 0;
    }

}

@media screen and (max-width:575px) {

    .login-image {
        padding: 25px;
    }

    .close-btn {
        right: 20px;
    }

}