/* button hover bg #3c6f8d */

.fashion-page {
    background-color: #E2E8F0;
    padding: 2em;
    border-radius: 10px;
}

.fashion-heading h6 {
    margin: 8px 0 16px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.assessLink {
    color: #37657F;
    text-decoration: none;
}

.fashion-content-image img {
    width: 100%;
}

.fashion-content-video {
    border-radius: 20px;
    width: 100%;
    max-height: 568px;
    object-fit: cover;
}

.fashion-info {
    gap: 20px;
}

.fashion-info-button {
    display: flex;
    gap: 30px;
    max-width: 350px;
    width: 100%;
}

.fashion-info-button .btn.button-outline {
    background-color: #F1F5F9 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    border: 1px solid #94A3B8;
    border-radius: 8px;
}

.ongoing_button {
    display: flex;
    gap: 20px;
}

.fashion-item-content h3 {
    margin: 0;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-content: center !important;
    margin-right: 10px
}

.fashion-item-content p {
    margin: 0;
    color: rgba(0, 0, 0, 0.60);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fashion-item-content > p {
    margin: 10px 0;
    color: #000000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fashion-item-content span {
    display: block;
    color: rgba(0, 0, 0, 0.67);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fashion-block, .fashion-item-content {
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
    padding-bottom: 3em;
    margin-bottom: 2em;
}
.testseen {
     background-color: white;
}

.fashion-block {
    border-top: 1px solid rgba(0, 0, 0, 0.20);
    margin-top: 3em;
    padding-top: 1em;
}

.fashion-rating-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    margin-top: 25px;
}

.fashion-rating-block span {
    color: rgba(0, 0, 0, 0.60);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 7px;
}

.fashion-rating-block ul{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 15px;
}

.fashion-rating-block ul li img {
    vertical-align: middle;
}

.fashion-review-content h6 {
    color: #334155;
    margin: 16px 0 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.fashion-review-content form {
    display: flex;
    gap: 15px;
}

.fashion-review-content input[type="text"] {
    background: #fff;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #bdc2c8;
    padding: 15px 10px;
    border-radius: 7px;
    width: 100%;
    min-width: 82%;
}

.fashion-review-content input[type="text"]:hover {
    border: 1px solid #205e83;
}

.fashion-review-content input[type="text"]:focus-visible {
    outline: none;
}
.fashion_review_arabic_block .MuiRating-root{
    flex-direction: row-reverse;
} 


/* -------***********------ */
.fashion-content-image {
    position: relative;
}

.fashion-content-image:hover .play-pause-block {
    display: block;
    transition: all 0.5s ease;
}

.fashion-content-image:hover .restore-button span {
    display: block;
    transition: all 0.5s ease;
}

.play-pause-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: none;
    transition: all 0.5s ease;
}

.play-pause-block svg.paly-button {
    display: none;
}

.play-pause-block svg.pause-buton {
    width: 50px;
    height: 112px;
    fill: #fff;
    display: block;
}

.play-pause-block.start-video svg.paly-button{
    display: block;
}

.play-pause-block.start-video svg.pause-buton{
    display: none;
}

.restore-button span {
    position: absolute;
    top: 50%;
    cursor: pointer;
    transform: translate(-25%, -50%);
    display: none;
    transition: all 0.5s ease;
}

.restore-button span.back-icon {
    left: 25%;
}

.restore-button span.next-icon {
    right: 25%;
    top: 50%;
    transform: translate(-25%, -50%) rotateY(180deg);
}

@media screen and (max-width: 1199px) {
    .fashion-review-content input[type="text"] {
        min-width: 70%;
    }

    .fashion-review-content form {
        flex-wrap: wrap;
    }

    .fashion-content-video {
        height: auto;
    }
}

@media screen and (max-width: 991px) {
    .restore-button span.next-icon {
        right: 18%;
        transform: translate(-18%, -50%) rotateY(180deg);
    }
}

@media screen and (max-width: 575px) {
 
    .fashion-info-button {
        flex-wrap: wrap;
        gap: 20px;
    }

    .fashion-info-button button {
        width: 100%;
    }

    .fashion-rating-block ul {
        gap: 10px;
    }
 
    .fashion-review-content form {
        flex-wrap: wrap;
    }

    .play-pause-block svg.paly-button {
        width: 30px;
        height: 30px;
    }

    .restore-button span.next-icon svg,  .restore-button span.back-icon svg{
        height: 30px;
    }

    .ongoing_button {
        display:inline;
    }
}

@media (max-width: 600px) {
    .center-on-small {
      justify-content: center !important;
    }
  }
  
  @media (min-width: 600px) {
    .right-on-large {
      justify-content: flex-end !important;
    }
  }

