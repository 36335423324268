*,
*::before,
*::after {
    box-sizing: border-box;
}

/* body {
    margin: 0;
    padding: 0;
    font-family: "Corbel V2";
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    box-sizing: border-box;
} */

p {
    margin: 0 0 20px;
}

.main-wrapper {
    background-color: rgb(55 101 127 / 20%);
    padding: 48px;
    /* display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 100vh; */
}

.signup-form-wrapper {
    display: flex;
    max-width: 1200px;
    margin: auto;
    background-color: #fff;
    border-radius: 20px;
    flex-wrap: wrap;
    height: 88vh;
    overflow: hidden;
}

/* .col-6 {
    width: 100%;
    max-width: 50%;
} */

.signup-block {
    position: relative;
}

.signup-block .login-form-wrapper {
    padding: 40px 0;
}

/* .form-image {
    text-align: center;
    background: #37657F;
    height: 100%;
    border-radius: 20px 0 0 20px;
}

.login-image {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
} */
/* 
.login-image.image-top {
    align-items: baseline;
}

.login-image.image-top img {
    height: auto;
} */

/* .form-image img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
} */

.login-form-wrapper {
    /* padding: 40px; */
    position: relative;
}

/* .close-btn {
    position: absolute;
    right: 30px;
    top: 20px;
    background: #e9ebef;
    display: block;
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.block-heading h4 {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 8px;
}

.form-group {
    display: block;
    margin-bottom: 15px;
}

.signup-form-wrapper .d-flex input {
    width: 100%;
}

label {
    display: block;
    margin-bottom: 16px;
    color: #334155;
    font-size: 16px;
    font-weight: 400;
}

input, select {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #CBD5E1;
    color: #37657F;
    display: block;
    width: 100%;
    min-width: 152px;
    background-clip: padding-box;
    background-color: transparent;
    min-height: 50px;
    font-size: 14px;
    line-height: 22px;
}

input::placeholder {
    color: rgb(55, 101, 127, 40%);
    font-family: "Corbel V2";
}

input:focus-visible, select:focus-visible {
    box-shadow: none;
    outline: none;
}

select:focus {
    background-color: white;
    color: #37657F;
}

.d-flex {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 15px;
}

.mb-4 {
    padding-bottom: 40px;
}

span {
    font-size: 14px;
}

.mt-35 {
    margin-top: 35px;
}

.otp-block input:focus {
    border: 1px solid #37657F !important;
}


.sign-up-scroll-block #gender {
    appearance: none;
    background: url("../downimage.svg") no-repeat 96% center;
}
.sign-up-scroll-block .arabic_gender_select #gender {
    appearance: none;
    background: url("../downimage.svg") no-repeat 0% center;
}
/* .btn {
    width: 100%;
    background: #37657F;
    border: none;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #37657F;
    font-size: 14px;
    font-weight: 600;
    font-family: "Corbel V2";
    letter-spacing: 0.5px;
    cursor: pointer;
    min-height: 50px;
} */

.button-outline {
    background-color: #F1F5F9;
    border: 1px solid #37657F;
    color: #37657F;
}

.align-baseline {
    align-items: baseline;
}

/* span.error-text .error {
    background: #FEE2E2;
    padding: 5px 10px;
    border-left: 4px solid #DC2626;
    border-radius: 4px;
    margin-top: 10px;
    color: #DC2626;
} */

span .error {
    padding-top: 10px;
    color: #DC2626;
}

/* .errorMsg {
    padding: 10px 0 0;
} */

/* span.or {
    padding: 10px 0;
    display: block;
    text-align: center;
} */

button.upload {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(148, 163, 184, 0.5);
    color: #6c7487;
    width: 100%;
    text-align: left;
    background: transparent;
    font-family: "Corbel V2";
    min-height: 50px;
}

.upload-btn-wrapper {
    position: relative;
}

.upload-btn-wrapper input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.upload-btn-wrapper svg {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    fill: #37657F;
    height: 18px;
}

.sign-up-scroll-block {
    height: calc(100vh - 390px);
    overflow-x: hidden;
    margin-right: -12px;
    padding-right: 12px;
}

.sign-up-scroll-block::-webkit-scrollbar {
    width: 4px;
}

.sign-up-scroll-block::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 2px;
    background: #D7E0E5;
}

.sign-up-scroll-block::-webkit-scrollbar-thumb {
    background: #37657F;
}

/* ============= Verify Block =============== */

.verification-code {
    height: 70px;
    border: 1px solid #94a3b88f;
    border-radius: 12px;
}

.block-content a {
    color: #37657F;
    font-weight: 400;
    font-size: 16px;
}

/* .block-content span.code {
    display: block;
    padding-bottom: 10px;
}

.block-content span a {
    margin-left: 10px;
} */

@media screen and (max-width: 1199px){
    .otp-block input {
        min-width: 50px !important;
        height: 60px !important;
    }

    .login-form-wrapper.fakehai {
        max-width: 350px;
    }

    .sign-up-scroll-block .d-flex.align-baseline {
        display: block;
    }
}

@media screen and (max-width:991px) {
    /* .d-flex {
        display: block;
    } */

    .close-btn, .close-btn.t-40 {
        top: 30px;
    }

    .otp-block input {
        min-width: 40px !important;
        height: 50px !important;
    }

    .block-content .send-span {
        padding: 20px 0 25px;
    }

    .signup-form-wrapper {
        height: auto;
    }
}

@media screen and (max-width:767px) {
    .signup-form-wrapper {
        display: block;
    }

    /* .col-6 {
        max-width: 100%;
    } */

    .d-flex {
        display: flex;
    }

    .form-image {
        border-radius: 20px 20px 0 0;
    }

    input, select {
        min-width: 202px;
    }

    .close-btn {
        top: 20px;
    }

}

@media screen and (max-width:575px) {
    /* .d-flex {
        display: block;
    } */

    .login-image {
        padding: 25px;
    }

    input, select {
        min-width: 100%;
    }

    p {
        margin: 0 0 10px;
    }

    .close-btn {
        right: 20px;
    }

    .signup-block .login-form-wrapper {
        max-width: 250px;
    }
        
        
}