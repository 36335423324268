.content-wrapper-assessment-test {
    height: calc(100vh - 110px);
    overflow-x: hidden;
    background: #F6F8FA;
    padding: 40px;
}

.content-wrapper-assessment-test  .content-wrapper-assessment-test-content{
    border: 1px solid #94a3b8;
    padding: 25px;
    border-radius: 10px;
    margin-bottom: 24px;
}

.content-wrapper-assessment-test-content .content-wrapper-assessment-test-content-box{
    border: 1px solid #94a3b8;
    padding: 25px;
    border-radius: 10px;
    margin-bottom: 24px;
    background-color: #F1F5F9;
}

.content-wrapper-assessment-test-content .main-title{
    font-weight: 700;
    font-size: 18px;
    color: #1E293B;
}

.content-wrapper-assessment-test-content .main-des{
    font-weight: 400;
    font-size: 16px;
    color: #37657F;
    margin-bottom: 25px;
}

.content-wrapper-assessment-test-content-box .question-label{
    color: #000;
    font-size: 16px;
    font-weight: 400;
    font-family: "Corbel V2";
}

.question-label span{
    color: #37657F;
    font-weight: 700;
    margin-right: 6px;
}

.content-wrapper-assessment-test-content-box .answer-radio .MuiRadio-root, .MuiFormControlLabel-root{
    padding: 0;
}

.content-wrapper-assessment-test-content-box .answer-radio .MuiFormControlLabel-root{
    margin: 0;
    width: 100%;
}

.answer-radio .radio-label-outline{
    background: #fff;
    border: 1px solid #CBD5E1;
    padding: 16px;
    border-radius: 6px;
}

.answer-radio .radio-label-outline .radio-label-inner{
    color: #37657F;
    font-size: 14px;
    font-weight: 400;
    font-family: "Corbel V2";
    margin-left: 6px;
}

.h-90px{
    height: 90px;
}

.content-wrapper-assessment-test-content-box .assessment-test-textarea{
    resize: none;
    font-family: 'Corbel V2';
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    outline: none;
    border: 1px solid #bdc2c8;
}

.content-wrapper-assessment-test-content-box .assessment-test-textarea::placeholder{
    color: #37657F;
    font-weight: 400;
}

.content-wrapper-assessment-test-content .question-label-end{
    font-size: 18px;
    font-weight: 700;
    color: #37657F;
}

.text-center{
    text-align: center;
}

.assessment-slider .course_slider .slick-next{
    top: -41px;
    right: 13px;
}

.assessment-slider .course_slider .slick-prev{
    top: -41px;
    right: 60px;
}

.assessment-slider .course_slider .slick-slide ,
.assessment-slider .upcoming-wrapper .slick-slide{
    margin: 0;
    padding-inline: 0;
}

@media only screen and (max-width: 1449px) {
    .assessment-slider .course_slider .slick-next{
        top: -41px;
        right: 37px;
    }
    
    .assessment-slider .course_slider .slick-prev{
        top: -41px;
        right: 85px;
    }
}

@media only screen and (max-width: 1366px) {
    .assessment-slider .course_slider .slick-next{
        top: -41px;
        right: 13px;
    }
    
    .assessment-slider .course_slider .slick-prev{
        top: -41px;
        right: 60px;
    }
}

@media only screen and (max-width: 1266px) {
    .assessment-slider .course_slider .slick-next{
        top: -41px;
        right: 37px;
    }
    
    .assessment-slider .course_slider .slick-prev{
        top: -41px;
        right: 85px;
    }
}

@media only screen and (max-width: 1090px) {
    .assessment-slider .course_slider .slick-next{
        top: -41px;
        right: 13px;
    }
    
    .assessment-slider .course_slider .slick-prev{
        top: -41px;
        right: 60px;
    }
}

@media only screen and (max-width: 830px) {
    .assessment-slider .course_slider .slick-next{
        top: -41px;
        right: 37px;
    }
    
    .assessment-slider .course_slider .slick-prev{
        top: -41px;
        right: 85px;
    }
}

@media only screen and (max-width: 767px) {
    .assessment-slider .course_slider .slick-next{
        top: -41px;
        right: 13px;
    }
    
    .assessment-slider .course_slider .slick-prev{
        top: -41px;
        right: 60px;
    }
}

@media only screen and (max-width: 620px) {
    .assessment-slider .course_slider .slick-next{
        top: -41px;
        right: 37px;
    }
    
    .assessment-slider .course_slider .slick-prev{
        top: -41px;
        right: 85px;
    }
}



.assessment-slider .course_slider .slick-list{
    height: fit-content !important;
}

.assessment-slider .slick-slide {
    height: auto;
    margin: 0 0 0 0;
}

.assessment-slider-second .course_slider .slick-list{
    height: fit-content !important;
}

.assessment-slider-main .assessment-slider-main-contant{
    padding:0 16px 10px;
}

.assessment-slider-main-contant .assessment-slider-main-contant-first{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.assessment-slider-main-contant .assessment-slider-main-contant-second{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap:10px;
    direction: ltr;
}

.assessment-slider-main-contant .assessment-slider-main-contant-third{
    padding: 0 8px;
    border: 1px solid #37657F;
    border-radius: 4px;
    width: fit-content;
    color: #37657F;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
}

.take-test-button{
    min-height: auto !important;
    padding: 6px 10px !important;
    border-radius: 8px !important;
}

.px-0{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.text-dark{
    color: #000;
}

.text-danger{
    color: red;
    font-size: 16px;
    font-weight: 400;
}

.test-result-modal .modal-header{
    padding: 16px !important;
}

.test-result-modal button.close{
    background: transparent !important;
}

.test-result-modal .test-result-modal-contant{
    padding: 24px;
    border-radius: 10px;
    border: 1px solid #37657F;
    background: #F1F5F9;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.test-result-modal-contant .get-grade{
    color: #37657F;
    font-size: 44px;
    font-weight: 700;
}

.test-result-modal-contant .total-grade{
    color: #64748B;
    font-size: 18px;
    font-weight: 400;  
}

.test-result-modal-contant .title-grade{
    color: #334155;
    font-size: 16px;
    font-weight: 400;
}

.test-result-modal .feedback-label{
    color: #334155;
    font-size: 14px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 10px;
}

.test-result-modal .divider-modal{
    border-top: 1px solid #E2E8F0;
    margin-top: 20px;
}

.ml-10{
    margin-left: 10px !important;
}

.mb-25{
    margin-bottom: 25px !important;
}

.assessments-text{
    color: #37657F !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: "Corbel V2";
}

.assessment-slider .course_slider .slick-track{
    display: flex !important;
    flex-direction: row-reverse !important;
}
.font-700{
    font-weight: 700;
}

.text-grey-2{
    color: rgba(0, 0, 0, 0.6);
}

.min-width-back{
    min-width: 120px !important;
    max-width: 120px !important;
}

@media only screen and (max-width: 450px) {
    .assessment-slider .course_slider .slick-list{
        height: 325px !important;
    } 

    .assessment-slider-main-contant .assessment-slider-main-contant-second{
        flex-direction: column;
    }

    .take-test-button{
        margin-top: 10px;
    }
}