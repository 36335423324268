.slick-slide {
    height: auto;
    margin: 0 20px 0 0;
}

button.history {
    background: #205e83;
    border: none;
    width: 47px;
    height: 47px;
    line-height: 55px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}

button.history svg {
    fill: #fff;
    font-size: 20px;
}

.home-search-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px;
    gap: 20px;
}

.home-search-block input {
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #bdc2c8;
    padding: 15px 10px 15px 40px;
    border-radius: 7px;
    background: #fff url("../searchimage.png") no-repeat 10px center;
    width: 100%;
}

.home-search-block input:hover {
    border: 1px solid #205e83;
}

.home-search-block input:focus-visible {
    outline: none;
}

.home-search-block form, .home-filter-block form {
    width: 100%;
}

.item-wrapper {
    border: 1px solid #94A3B8;
    padding: 25px;
    border-radius: 10px;
    background: #F8FAFC;
    margin-bottom: 40px;
}

.inner-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.inner-heading h6 {
    color: #000;
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.198px;
}

.inner-heading a.view-link {
    color: #37657F;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}

.course-tab ul {
    flex-wrap: wrap;
    list-style: none;
    padding: 5px;
    margin: 0;
    background: #E2E8F0;
    display: inline-flex;
    border-radius: 10px;
    align-items: center;
}

.course-tab ul li button {
    font-size: 14px;
}

.course-tab ul li span button {
    padding: 10px 15px;
    border-radius: 8px;
    color: #37657F;
    font-family: "Corbel V2";
    cursor: pointer;
    border: none;
    background: transparent;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    min-width: 100px;
}

.course-tab-content {
    display: flex;
    gap: 16px;
    padding-bottom: 10px;
}

.course-tab-block {
    border: 1px solid #94A3B8;
    border-radius: 10px;
    background: #F1F5F9;
    padding: 0 0 16px;
}

.mycourse-curser {
    cursor: pointer;
    height: 32px;
}

.course_slider .slick-list {
    height: 255px !important;
}

.upcoming-wrapper .slick-list {
    height: 325px !important;
}

.tab-process-wrapper {
    padding: 20px;
}

.course-tab-block:not(:last-child) {
    margin-right: 16px;
}

.course-tab-block img {
    height: 120px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;
}

.course-tab-block small, .upcoming-content small {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: rgba(0, 0, 0, 0.60);
    padding: 7px 16px 7px;
    display: block;
}

.tab-process-block span {
    color: rgba(0, 0, 0, 0.80);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.course-tab-block h6, .upcoming-content h6 {
    margin: 3px 0 0;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0 16px;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab-process-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 5px;
}
.linear_progress_bar_block{
    padding: 0 16px 5px;
}
.linear_progress_bar_block .MuiLinearProgress-colorPrimary.process-bar-clr{
    background-color: #E2E8F0 !important;
    border-radius: 10px;
}
.course_slider_content_wrap{
    height: 75px;
    overflow-y: scroll;
}
.arabic_course_slider .course_slider_content_wrap {
    text-align: right;
}
/* .tab-process-block p {
    width: 100%;
    height: 5px;
    background: #94A3B8;
    border-radius: 10px;
} */
/* width */
::-webkit-scrollbar {
    width: 3px;
  }
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.course-tab-block p, .upcoming-content p {
    color: rgba(0, 0, 0, 0.60);
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 16px;
}
.course-tab-nav li.current button{
    background: #205e83;
    color: #fff;
}

.upcoming-block {
    padding: 0 0 20px;
    border: 1px solid #94A3B8;
    border-radius: 10px;
    background-color: #F1F5F9;
}
.upcoming-image img {
    width: 100%;
    height: 113px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    margin-bottom: 16px;
}

.upcoming-time {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 16px 0;
}
.arabic_upcoming_course_slider .upcoming-time{
    justify-content: right;
}
.arabic_upcoming_course_slider .upcoming-time span{
    padding-left: 16px;
    padding-right: 0;
    gap: 4px;
}
.mycourseimg {
    border: "1px solid red";
    height: 100px
}

.upcoming-time span {
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.60);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
}

.upcoming-time span svg {
    vertical-align: sub;
    margin-right: 7px;
}

.upcoming-time span:last-child {
    font-weight: 300;
    display: flex;
}

.upcoming-time span:last-child p {
    margin: 0;
}

.upcoming-button button {
    width: 100%;
}

.upcoming-button {
    display: flex;
    gap: 16px;
    align-items: stretch;
    justify-content: center;
    padding: 5px 16px;
}

.upcoming-button button.button-gray {
    background: #CBD5E1;
    padding: 13px 15px;
    border-radius: 7px;
    color: #37657F;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-family: "Corbel V2";
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    width: 100%;
    min-width: 135px;
}

.upcoming-button button svg {
    margin-left: 5px;
}

.upcoming-button .button-outline {
    background-color: #E2E8F0;
    color: #000000;
    opacity: 0.5;
    border: 1px solid #64748B;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.upcoming-button .button-outline:hover {
    background-color: #E2E8F0 !important;
    color: #000000 !important;
    opacity: 0.5;
    border: 1px solid #64748B; 
    cursor: not-allowed;
}

.course-tab-content::-webkit-scrollbar {
    height: 3px;
    background-color: transparent;
}

.course-tab-content::-webkit-scrollbar-thumb {
    background: rgb(32, 94, 131, 0.5);
    border-radius: 20px;
}
.course-tab-link {
    margin-top: 25px;
}
.course_slider .slick-slide ,
.upcoming-wrapper .slick-slide{
    margin: 0;
    padding-inline: 16px;
}
.course_slider .slick-track,
.upcoming-wrapper .slick-track{
    margin-inline: -8px;
}

.course_slider .slick-arrow.slick-next {
    top: -117px;
    right: 37px;
    left: unset;
}
.course_slider.arabic_course_slider .slick-arrow.slick-next {
    left: 5%;
}
.course_slider .slick-arrow.slick-prev {
    top: -117px;
    left: auto;
    right: 85px !important;  
}
.course_slider.arabic_course_slider .slick-arrow.slick-prev {   
    left: 0;
}
.course_slider .slick-prev::before {
    content: "";
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADQSURBVHgB7ZM9CsJAEIVnzdjnCB4haiHYKx7BzoDYRyxEbEwlRIv0WngNCRJtJFXMMSxTigTXTSEWgjvZiNV+1RZv3sf+AWg0ZWGggO14FiIbZBm6O3+cfstWQKm8Eoqlg3izZPlCgnc5NwEe7nY9O8pmyEf0WT5dUOZIAtVyksB2ljXE6kWlPId2B5y/4hwKYsgCSXRIm63OHhjri/32Gu0uxOfgBESkgpw4Cq6qEpKgjKTwTx6JF8UNFopJk7F7fbOaJ/Brcslw4vmg0fyFJ+RFT+b4nH8IAAAAAElFTkSuQmCC) no-repeat center;
    width: 32px;
    height: 32px;
    top: 75px;
    left: 0;
    background-color: #CBD5E1;
    border-radius: 6px;
}

.course_slider .slick-next::before {
    content: "";
    position: absolute;
    background: url("../rightArrow.png") no-repeat center;
    width: 32px;
    height: 32px;
    top: 75px;
    left: 0;
    background-color: #37657F;
    border-radius: 6px;
}

.upcoming-wrapper .slick-next {
    top: -40px;
    right: 38px;
    left: unset;
}
.arabic_upcoming_course_slider.upcoming-wrapper .slick-next {
    left: 5%;
}
.upcoming-wrapper .slick-prev {
    top: -40px;
    left: auto;
    right: 85px;
}
.arabic_upcoming_course_slider.upcoming-wrapper .slick-prev {
    left: 0;
}

.upcoming-wrapper .slick-prev::before {
    content: "";
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADQSURBVHgB7ZM9CsJAEIVnzdjnCB4haiHYKx7BzoDYRyxEbEwlRIv0WngNCRJtJFXMMSxTigTXTSEWgjvZiNV+1RZv3sf+AWg0ZWGggO14FiIbZBm6O3+cfstWQKm8Eoqlg3izZPlCgnc5NwEe7nY9O8pmyEf0WT5dUOZIAtVyksB2ljXE6kWlPId2B5y/4hwKYsgCSXRIm63OHhjri/32Gu0uxOfgBESkgpw4Cq6qEpKgjKTwTx6JF8UNFopJk7F7fbOaJ/Brcslw4vmg0fyFJ+RFT+b4nH8IAAAAAElFTkSuQmCC) no-repeat center;
    width: 32px;
    height: 32px;
    top: 0px;
    left: 0;
    background-color: #CBD5E1;
    border-radius: 6px;
}

.upcoming-wrapper .slick-next::before {
    content: "";
    position: absolute;
    background: url("../rightArrow.png") no-repeat center;
    width: 32px;
    height: 32px;
    top: 0px;
    left: 0;
    background-color: #37657F;
    border-radius: 6px;
}

.upcoming-content {
    height: 110px;
    overflow: auto;
}
.arabic_upcoming_course_slider .upcoming-content{
    text-align: right;
}

/* .slick-arrow.slick-next {
    left: 620px;
    
  }
  .slick-arrow.slick-prev {
    right: 155px;
  } */

@media screen and (max-width: 1366px){ 
    .course_slider .slick-prev, .upcoming-wrapper .slick-prev{
        left: auto;
        right: 85px;
    }

    .upcoming-button {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1199px){ 
    .upcoming-block:not(:last-child) {
        margin-bottom: 16px;
    }
     .course_slider .slick-prev, .upcoming-wrapper .slick-prev  {
        left: auto ;
        right:155px;
    } 
}

@media screen and (max-width: 991px) {
    .page-wrapper {
        max-width: 480px;
    }
 
    .course_slider .slick-prev, .upcoming-wrapper .slick-prev  {
        left: auto;
    } 
    .course_slider.arabic_course_slider .slick-arrow.slick-next {
        left: 10%;
    }
}


@media screen and (max-width: 767px) {
    .page-sidebar {
        display: none;
    }

    .page-wrapper {
        max-width: 100%;
    }

     .course_slider .slick-prev, .upcoming-wrapper .slick-prev  {
        left: auto;
        right: 85px;
    } 
    .course_slider.arabic_course_slider .slick-arrow.slick-next {
        left: 13%;
    }
}

@media screen and (max-width: 575px) {

    .upcoming-image, .upcoming-button .button-outline {
        max-width: 100%;
    }

    .upcoming-button {
        margin-top: 10px;
    }

    .course_slider .slick-prev, .upcoming-wrapper .slick-prev  {
        left: auto ;
        right: 85px;
    } 
    .arabic_upcoming_course_slider.upcoming-wrapper .slick-next {
        left: 16%;
    }

}
@media screen and (max-width: 420px){

    .course_slider .slick-prev, .upcoming-wrapper .slick-prev  {
        left: auto;
        right:85px;
    } 
}
@media screen and (max-width: 315px){
    .course_slider .slick-prev, .course_slider .slick-next {
        top: -160px;
    }

    .course_slider .slick-prev, .upcoming-wrapper .slick-prev {
        left: auto;
        right: 85px;
    } 

    .upcoming-wrapper .slick-prev, .upcoming-wrapper .slick-next {
        top: -30px;
    }

    .inner-heading {
        padding-bottom: 20px;
    }
}

@media(max-width:1034px){
    .item-wrapper {
        padding: 9px;
        padding-left: 135px;
    }
}

@media(max-width:992px){
    .item-wrapper {
        padding: 30px;
    }
}
@media(max-width:833px){
    .course_slider .slick-list{
        height: 310px !important;
    }
}
@media(max-width:1366px){
.upcoming-wrapper .slick-list {
    height: 405px !important;
}
}
@media(max-width:850px){
    .upcoming-wrapper .slick-list{
        height: 465px !important;
    }
}