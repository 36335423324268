.raffles-about-block h6 {
    color: #000;
    font-family: "Corbel V2";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.198px;
    margin: 0 0 25px;
}

.raffles-about-content {
    background: #fff;
    padding: 35px;
    height: calc(100vh - 190px);
    overflow-x: hidden;
}

.raffles-about-content-image {
    margin-bottom: 40px;
}

.raffles-about-content-image img {
    width: 100%;
}

.raffles-about-content p {
    color: #000;
    font-family: "Corbel V2";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin: 0; */
}

.raffles-about-content br {
    display: none;
}

.raffles-about-inner-content:not(:last-child){
    margin: 0 0 20px;
}

.raffles-about-content::-webkit-scrollbar {
    width: 4px;
}

.raffles-about-content::-webkit-scrollbar-track {
    background-color: #D7E0E5;;
    border-radius: 2px;
}

.raffles-about-content::-webkit-scrollbar-thumb {
    background: #205e83;
    border-radius: 2px;
}

.content-wrapper.about-content-raffles {
    overflow: hidden;
}

@media screen and (max-width: 767px) {
    .page-title.about-raffles {
        margin-left: 0;
    }
}