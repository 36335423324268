.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
}

.main-landing-wrapper {
  height: 100vh;
  overflow-x: hidden;
}

/* ================ Header CSS ================= */

.landing-header-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
}

.landing-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.landing-navigation ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 20px;
}

.header-button {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
}

.landing-navigation ul li a {
  text-decoration: none;
  color: #101828;
  font-family: "Corbel V2";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}

.landing-navigation ul li div {
  text-decoration: none;
  color: #101828;
  font-family: "Corbel V2";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  cursor: pointer;
}

.header-button .button-link {
  color: #37657f;
  font-family: "Corbel V2";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  background: #fff;
  border: none;
  box-shadow: none;
  cursor: pointer;
  width: auto;
}

.header-button .btn.button {
  width: 150px;
}

.button {
  border-radius: 8px;
  border: 1px solid #37657f;
  background: #37657f;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 16px;
  color: #ffffff;
  font-family: "Corbel V2";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
}

.course-image {
  width: 384px;
  height: 262px;
}

button.button-white {
  border-radius: 8px;
  border: 1px solid #f1f5f9;
  background: #f1f5f9;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 16px 28px;
  color: #37657f;
  font-family: "Corbel V2";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  cursor: pointer;
}

.main-landing-wrapper .mobile-menu svg {
  display: none;
}

.header-logo img {
  width: 140px;
  height: 97px;
  object-fit: cover;
}

/* ================ Banner Section CSS ================ */

.banner-section {
  padding: 0 0 47px;
}

.banner-outline {
  border-radius: 18px;
  border: 1px solid #37657f;
  background: #fff;
  padding: 18px;
}

.banner-course-block {
  position: absolute;
  top: 0%;
  right: -15%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}

.banner-tutors-block {
  position: absolute;
  bottom: 12%;
  right: -25%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}

.banner-videos-block {
  position: absolute;
  bottom: 12%;
  left: -30%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
}

.banner-videos-block p {
  margin: 0;
}

.banner-tutors-block p {
  margin: 0;
}

.banner-course-block p {
  margin: 0;
}

.banner-svg {
  border-radius: 10px;
  background: #37657f;
  display: flex;
  width: 46px;
  height: 46px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.banner-videos-content span,
.banner-tutors-content span {
  display: block;
  color: #101828;
  font-family: "Corbel V2";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 6px;
}

.banner-course-block span {
  display: block;
  color: #101828;
  font-family: "Corbel V2";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.banner-tutors-content span {
  margin: 0;
}

.overlay-bg {
  width: 460px;
  height: 460px;
  border-radius: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  opacity: 0.3;
  background: #37657f;
  top: 8%;
  left: -5%;
}

.banner-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.banner-content {
  width: 65%;
  flex: 0 0 auto;
}

.banner-image {
  width: 35%;
  flex: 0 0 auto;
  position: relative;
  text-align: center;
}

.banner-image img {
  width: 100%;
  max-width: 355px;
}

.banenr-learning {
  position: absolute;
  background: #fff;
  color: #64748b;
  font-family: "Corbel V2";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  padding: 9px;
  bottom: -8%;
}

.banenr-learning p {
  margin: 0;
}

.banenr-learning span {
  color: #37657f;
  font-weight: 700;
  font-size: 18px;
}

.banner-content h1 span {
  color: #37657f;
  font-family: "Corbel V2";
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.banner-content h1 {
  color: #101828;
  font-family: "Corbel V2";
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.banner-content p {
  margin: 16px 0 20px;
  color: #334155;
  font-family: "Corbel V2";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.banner-button {
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 28px;
}

.banner-button .button {
  padding: 16px 28px;
  font-family: "Corbel V2";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.banner-content ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 20px 0 0;
  margin: 0;
  gap: 34px;
}

.banner-content ul li {
  display: flex;
  align-items: center;
  gap: 10px;
}

.banner-learning-dot-block {
  position: absolute;
  right: -25%;
  bottom: 2%;
}

/* =============== Top Company CSS ============== */

.top-company-section {
  background: rgba(195, 209, 217, 0.3);
  padding: 40px 0;
  margin: 0 0 100px;
}

.company-content {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  gap: 23px;
}

.company-content ul {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 36px;
}

.company-content h3 {
  margin: 0;
  color: #1e293b;
  font-family: "Corbel V2";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  max-width: 248px;
}

.company-content ul li img {
  width: 100%;
}

/* ================ Program Section ================ */

.program-section {
  margin: 0 0 150px;
}

.program-title h2 {
  color: #37657f;
  font-family: "Corbel V2";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0 0 10px;
}

.program-title-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 25px;
  margin: 0 0 40px;
}

.program-title p {
  margin: 0;
  color: #475569;
  font-family: "Corbel V2";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.program-wrapper {
  border-radius: 10px;
  border: 1px solid #edf1f4;
  background: #edf1f4;
  /* margin: 0 12px; */
}

.program-section .slick-list {
  margin: 0 -12px;
}

.program-slide-block {
  padding: 0 8px;
}

.program-wrapper > img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.program-content h2 {
  color: #37657f;
  font-family: "Corbel V2";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0 0 8px;
}

.program-content {
  padding: 32px 24px;
  overflow: auto;
}

.program-content-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
.program-content-description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50;
}

.program-content-item h4 {
  margin: 0 0 6px;
  color: #101828;
  font-family: "Corbel V2";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.no-break-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.program-content p {
  color: #667085;
  font-family: "Corbel V2";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.program-inner-block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin: 20px 0 0;
}

.program-inner-content {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.program-inner-block span {
  color: #37657f;
  font-family: "Corbel V2";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.program-inner-content-item h5 {
  color: #101828;
  font-family: "Corbel V2";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.program-section .slick-dots {
  bottom: -65px;
}

.program-inner-content img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.landing-content .slick-slide {
  margin: 0;
}

/* ================= About Section CSS ================ */

.about-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 50px;
}

.about-image img {
  width: 100%;
}

.about-content {
  width: 100%;
  max-width: 657px;
}

.about-section {
  background: #edf1f4;
  padding: 65px 0px;
  margin: 0 0 100px;
}

.about-content h5 {
  color: #37657f;
  font-family: "Corbel V2";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px 0 20px;
}

.about-content p {
  color: #475569;
  font-family: "Corbel V2";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 15px;
}

/* ================ Service Section ================ */

.service-section {
  margin: 0 0 150px;
}

.service-section .slick-list {
  height: max-content !important;
}

.service-section .slick-dots {
  bottom: -65px;
}

.service-title h2 {
  color: #37657f;
  font-family: "Corbel V2";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.service-title p {
  color: #475569;
  font-family: "Corbel V2";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
}

.service-title {
  margin: 0 0 40px;
}

.service-wrapper {
  background: #edf1f4;
  padding: 30px 40px;
  margin: 10px 0;
}

.service-content-block {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin: 0 0 30px;
}

.service-content-item p {
  color: #0f172a;
  font-family: "Corbel V2";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin: 0 0 20px;
  height: 4.2em;
}

@media only screen and (max-width: 1200px) {
  .service-content-item p {
    font-size: 18px;
  }
  .service-content-block {
    align-items: start;
    flex-direction: column;
  }
}

.service-content-block h3 {
  color: #101828;
  font-family: "Corbel V2";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
}

.service-content-item a {
  color: #37657f;
  font-family: "Corbel V2";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;
}

.service-content-item div {
  cursor: pointer;
  color: #37657f;
  font-family: "Corbel V2";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;
}

.service-content-item {
  width: 100%;
  max-width: 295px;
}

.service-content-item svg {
  margin-left: 20px;
}

.service-content-image {
  border-radius: 10px;
  background: #37657f;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 11px;
}

.slick-center .service-wrapper {
  background: #fff;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.2),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09);
}

/* ================== Info Section CSS =================== */

.info-section .slick-dots {
  bottom: -120px;
}

.landing-content .slick-dots li.slick-active {
  width: 50px;
}

.landing-content .slick-dots li.slick-active button::before {
  opacity: 1;
  width: 50px;
  height: 13px;
  border-radius: 10px;
  background: #37657f;
  content: "";
}

.landing-content .slick-dots li button:before:hover {
  transition: all 0.2s ease;
}

.landing-content .slick-dots li button:before {
  content: "";
  width: 13px;
  height: 13px;
  background: rgb(55 101 127/ 0.8);
  border-radius: 50%;
  transition: all 0.2s ease;
}

.info-content {
  text-align: center;
  max-width: 1140px;
  margin: auto;
}

.info-section {
  background: #edf1f4;
  padding: 60px 0;
  margin: 0 0 150px;
}

.info-content img {
  margin: auto;
}

.info-content h2 {
  color: #101828;
  text-align: center;
  font-family: "Corbel";
  font-size: 34px;
  font-weight: 700;
  line-height: 41.06px;
  margin: 24px 0 30px;
}

.info-content h5 {
  color: #101828;
  text-align: center;
  font-family: "Corbel";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 16px 0 5px;
}

.info-content p {
  color: #667085;
  text-align: center;
  font-family: "Corbel";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
}

/* ================ Footer CSS ================ */

footer.site-footer {
  background: #00273e;
  padding: 40px 0;
}

.main-footer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-top,
.footer-bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-bottom-right ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 24px;
}

.footer-top-right {
  position: relative;
  bottom: -3px;
}

.footer-top-right:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 214px;
  height: 190px;
  background: rgb(55 101 127 / 30%);
  border-radius: 50%;
  transform: translate(-50%);
}

.footer-top-left ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 40px;
}

.footer-top-left ul li a {
  text-decoration: none;
  color: #f1f5f9;
  font-family: "Corbel V2";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-top-left ul li div {
  cursor: pointer;
  text-decoration: none;
  color: #f1f5f9;
  font-family: "Corbel V2";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-bottom-left p {
  color: #98a2b3;
  font-family: "Corbel V2";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.footer-top-left h4 {
  color: #eaecf0;
  font-family: "Corbel V2";
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 16px 0 24px;
}

.footer-top-left img {
  margin: 0 0 20px;
}

.footer-top-left {
  max-width: 380px;
  padding: 0 0 40px;
}

.footer-top {
  border-bottom: 1px solid #94a3b8;
}

.footer-top-right img {
  position: relative;
  bottom: 5px;
}

.footer-bottom {
  padding: 40px 0 0 0;
  gap: 30px;
}

/* @media screen and (max-width: 1366px) {

    .banner-content {
        width: 55%;
    }
} */
header.site-header {
  position: sticky;
  top: 0;
  z-index: 9999;
  left: 0;
  right: 0;
  background: #fff;
}

@media screen and (max-width: 1366px) {
  .about-content {
    max-width: 657px;
  }

  .banner-content {
    width: 50%;
  }

  .banner-content ul {
    flex-direction: column;
    align-items: flex-start;
  }

  .banner-wrapper {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1199px) {
  .container {
    max-width: 960px;
  }

  .about-content {
    max-width: 417px;
  }

  .banner-content h1,
  .banner-content h1 span {
    font-size: 40px;
  }

  .banner-content ul {
    flex-direction: row;
  }

  .banner-tutors-block,
  .banner-learning-dot-block {
    right: -20%;
  }

  .banner-course-block {
    right: -10%;
  }

  .banner-videos-block {
    bottom: 8%;
    left: -22%;
  }

  .banner-image {
    width: 45%;
  }

  .company-content {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 991px) {
  .container {
    max-width: 720px;
  }

  .company-content {
    text-align: center;
  }

  .company-content ul {
    flex-wrap: wrap;
  }

  .about-wrapper {
    gap: 30px;
  }

  .about-image {
    width: 100%;
  }

  .about-content {
    max-width: 100%;
  }

  .info-content h2 {
    font-size: 28px;
  }

  .banner-wrapper {
    justify-content: center;
    flex-direction: column-reverse;
  }

  .banner-content {
    width: 100%;
    margin-top: 50px;
  }

  .banner-image {
    width: 60%;
  }

  .service-content-item {
    width: 100%;
    max-width: 100%;
  }

  .banner-course-block {
    right: -10%;
  }

  .banner-tutors-block {
    right: -20%;
  }

  .banner-videos-block {
    left: -25%;
  }

  .banenr-learning {
    bottom: -7%;
  }

  .landing-header-wrapper {
    justify-content: center;
  }

  .slick-center .service-wrapper {
    background: #edf1f4;
    box-shadow: none;
  }

  .banner-learning-dot-block {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  /* .container {
        max-width: 540px;
    } */
  .landing-header-wrapper {
    padding: 20px 0px;
  }

  .site-footer {
    text-align: center;
  }
  .footer-top-right {
    flex: 0 0 100%;
  }
  .footer-top,
  .footer-bottom {
    justify-content: center;
  }

  .company-content h3 {
    font-size: 28px;
  }

  .info-content p {
    font-size: 18px;
  }

  .info-content h5 {
    font-size: 20px;
  }

  .company-content ul {
    gap: 25px;
  }

  .banner-image {
    width: 80%;
  }

  .banenr-learning {
    position: relative;
    bottom: 0;
  }

  .banner-outline {
    position: relative;
    left: 0;
    right: 0;
  }

  .banner-outline:not(:last-child) {
    margin: 0 0 20px;
  }

  .banner-content h1 span {
    font-size: 34px;
  }

  .service-wrapper {
    padding: 30px 20px;
  }

  .program-content {
    padding: 20px 15px;
  }

  .overlay-bg {
    display: none;
  }

  .info-section,
  .service-section,
  .info-section,
  .about-section,
  .program-section,
  .top-company-section {
    margin: 0 0 80px;
  }

  .landing-header-wrapper {
    justify-content: flex-start;
  }

  .main-landing-wrapper .mobile-menu {
    position: relative;
  }

  .main-landing-wrapper .mobile-menu > svg {
    display: block;
    position: absolute;
    top: 40px;
    right: 30px;
    z-index: 2;
  }
  .main_landing_arabic_wrapper .mobile-menu > svg {
    left: 10px;
    right: unset !important;
    top: 45px;
  }
  .mobile-menu svg {
    fill: #aa9973;
  }
  .header-button {
    margin-left: 0;
    justify-content: flex-start;
  }

  .landing-navigation {
    display: none;
  }

  .main-landing-wrapper .menu-mobile.mobile-menu > svg {
    fill: #fff;
  }

  .main-landing-wrapper .menu-mobile .landing-navigation {
    width: 35%;
    background: #37657fe3;
    position: absolute;
    top: 40px;
    right: 0;
    padding: 50px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 20px;
  }
  .main_landing_arabic_wrapper .menu-mobile .landing-navigation {
    right: unset !important;
    left: 0;
  }
  .landing-navigation ul {
    display: block;
    margin: 0;
    text-align: center;
  }

  .landing-navigation ul li a {
    color: #fff;
  }

  .landing-navigation ul li div {
    color: #fff;
  }

  svg.close-menu-landing {
    display: none !important;
  }

  .main-landing-wrapper .menu-mobile svg.close-menu-landing {
    display: block !important;
  }

  .main-landing-wrapper .menu-mobile > svg {
    display: none;
  }

  .header-button .button-link {
    color: #fff;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #37657f;
    background: #37657f;
    margin-bottom: 5px;
  }

  .header-button {
    gap: 0;
  }

  .header-button .button {
    width: 100%;
    padding: 2px;
    text-align: center;
  }
}

@media screen and (max-width: 575px) {
  .container {
    max-width: 100%;
  }

  .footer-top-left ul {
    gap: 20px;
    justify-content: center;
  }

  .info-content h2 {
    font-size: 24px;
  }

  .banner-image {
    width: 100%;
  }

  .banner-button {
    flex-direction: column;
  }

  .banner-button button {
    width: 100%;
  }

  .landing-navigation ul {
    margin: 0;
  }

  .main-landing-wrapper .mobile-menu > svg {
    right: 50px;
  }

  .main-landing-wrapper .menu-mobile .landing-navigation {
    right: 30px;
  }
  .main-landing-wrapper .menu-mobile .landing-navigation {
    width: 45%;
  }
}

@media screen and (max-width: 375px) {
  /* .header-button .button {
        text-align: left;
        padding-left: 0;
        padding-top: 0;
    } */
  .landing-header-wrapper {
    padding: 10px 0px;
  }
  .main-landing-wrapper .mobile-menu > svg {
    right: 25px;
    top: 40px;
  }

  .main-landing-wrapper .menu-mobile .landing-navigation {
    right: 10px;
  }
}