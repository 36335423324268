html, body, #root, #root > div {
    width: 100% !important;
    height: auto !important;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* body {
    margin: 0;
    padding: 0;
    font-family: "Corbel V2";
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    box-sizing: border-box;
} */

p {
    margin: 0 0 20px;
}

.main-wrapper {
    background-color: rgb(55 101 127 / 20%);
    padding: 38px;
}

.signin-form-wrapper {
    display: flex;
    max-width: 1200px;
    margin: auto;
    background-color: #fff;
    border-radius: 20px;
    flex-wrap: wrap;
}

.align-center {
    align-items: center;
}

.col-6 {
    width: 100%;
    max-width: 50%;
}

.form-image {
    text-align: center;
    background: #37657F;
    height: 100%;
    padding: 40px;
    border-radius: 20px 0 0 20px;
}

.login-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #fff;
    border-radius: 14px;
    width: 100%;
    min-height: 78vh;
    max-height: 78vh;
}


.form-image img {
    width: 100%;
    /* height: 100%;
    object-fit: cover; */
    max-width: 300px;
}

.login-form-wrapper {
    /* padding: 40px; */
    position: relative;
}

.close-btn svg {
    font-size: 18px;
}

/* .close-btn.tp-20 {
    position: absolute;
    right: 30px;
    top: 20px;
    background: rgba(217, 217, 217, 0.50);
    display: block;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.block-heading h4 {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 8px;
}

.form-group {
    display: block;
    position: relative;
}

.d-flex .form-group {
    width: 100%;
}

.form-group:not(:last-child) {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 10px;
    color: #334155;
    font-size: 16px;
    font-weight: 400;
}

input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #6c7487;
    color: #6c7487;
    display: block;
    width: 100%;
    background-clip: padding-box;
}

/* input::placeholder {
    color: #6c7487;
} */

input:focus-visible {
    box-shadow: none;
    outline: none;
}

.d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mb-4 {
    padding-bottom: 2rem;
}

.d-flex label {
    display: inline-block;
    margin-bottom: 16px;
    font-weight: 300;
}

.remember-text {
    display: flex;
}

/* .form-group {
    display: block;
    margin-bottom: 15px;
}*/

/* .remember-text input {
    display: none !important;
} */

.remember-text label {
    position: relative;
    cursor: pointer;
    width: 100%;
    min-width: 170px;
    font-size: 14px;
    color: #000;
    padding-left: 10px;
    margin-bottom: 0;
}

/* .remember-text label:before {
    content: '';
    appearance: none;
    background-color: transparent;
    border: 2px solid #37657F;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.remember-text input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: 20px;
    width: 6px;
    height: 14px;
    border: solid #37657F;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
} */

.d-flex .remember-text input {
    min-width: 20px;
    width: inherit;
    display: inline-block;
    min-height: auto;
}

.block-content span.forget {
    font-family: "Corbel V2";
    font-style: italic;
    font-weight: 400;
    padding-bottom: 0;
    color: #37657F;
    font-size: 16px;
    text-align: right;
}

span.password {
    position: absolute;
    right: 10px;
    top: 63px;
    fill: #94A3B8;
    font-size: 16px;
}

span.password svg.show-pass {
    display: block;
}

span.password svg.hide-pass {
    display: none;
}

span.password.active-pass svg.show-pass {
    display: none;
}

span.password.active-pass svg.hide-pass {
    display: block;
}

/* .btn {
    width: 100%;
    background: #6c7487;
    border: none;
    padding: 7px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #6c7487;
    font-size: 14px;
    font-weight: 600;
    font-family: "Corbel V2";
    letter-spacing: 0.5px;
    cursor: pointer;
} */

.btn.button-outline {
    background-color: #F1F5F9;
    border: 1px solid #37657F;
    color: #37657F;
}

span.or {
    padding: 24px 0;
    display: block;
    text-align: center;
    color: #000;
    font-size: 14px;
    line-height: normal;
}

@media screen and (max-width:991px) {
    .d-flex {
        display: block;
    }

    .col-6 {
        max-width: 100%;
    }

    .login-form-wrapper {
        max-width: 600px;
    }
    
    .main-wrapper {
        padding: 0 !important;
    }

}

@media screen and (max-width:767px) {
    .signin-form-wrapper {
        display: block;
    }

    .d-flex {
        display: flex;
    }

    .login-form-wrapper {
        max-width: 400px;
    }
}

@media screen and (max-width:575px) {
    .d-flex {
        display: block !important;
    }
    .login-form-wrapper {
        max-width: 340px;
    }

    .d-flex.mb-4 {
        display: flex !important;
    }

    .login-image {
        min-height: 60vh;
    }
}