.content-wrapper {
    height: calc(100vh - 110px);
    overflow-x: hidden;
}

.content-wrapper .MuiAccordion-root{
    background-color: transparent !important;
}

.content-wrapper .MuiAccordion-root .MuiAccordionSummary-root, .content-wrapper .MuiAccordion-root .MuiAccordionDetails-root{
    padding: 0 !important;
}
.content-wrapper .MuiAccordion-root .MuiAccordionSummary-content.Mui-expanded{
    margin: 0 !important;
}
button.history {
    background: #205e83;
    border: none;
    width: 47px;
    height: 47px;
    line-height: 55px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}

button.history svg {
    fill: #fff;
    font-size: 20px;
}

.home-search-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px;
    gap: 20px;
}

.home-search-block input {
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #bdc2c8;
    padding: 10px 10px 10px 40px;
    border-radius: 7px;
    width: 100%;
}

.home-search-block input:hover {
    border: 1px solid #205e83;
}

.home-search-block input:focus-visible {
    outline: none;
}

.home-search-block form, .home-filter-block form {
    width: 100%;
    margin: 0;
}

.home-page-row {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.home-page-col-3 {
    width: calc(33% - 20px);
}

.home-page-col-9 {
    width: calc(66% - 0px);
}

.home-category-block {
    border: 1px solid #bdc2c8;
    border-radius: 10px;
    padding: 40px;
    background: #fff;
    height: 100%;
}

.home-category-block h3 {
    margin: 0 0 30px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.198px;
}

.home-category-content {
    border: 1px solid #37657F;
    border-radius: 10px;
    background: #F1F5F9;
}

.home-category-content:not(:last-child) {
    margin: 0 0 15px;
}

.home-category-content img {
    width: 100%;
    border-radius: 8px 8px 0 0;
    height: 120px;
    object-fit: cover;
}

.home-categorycard-content-des{
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.home-categorycard-content-des .home-categorycard-content-review{
    display: flex;
    align-items: center;
}

.home-categorycard-content-des .home-categorycard-content-review span {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #000;
}

.home-categorycard-content-des svg{
    width: 16px;
    height: 16px;
    fill: rgba(245, 158, 11, 1);
}

.home-category-content h6 {
    margin: 0 0 4px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #000;
}

.home-category-content h5 {
    margin: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #000;
}

.home-category-content p {
    margin: 0;
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home-category-block-content {
    overflow-y: auto;
    height: 545px;
}

.home-category-block-content-norecord, .home-category-block-content-norecord-loader {
    overflow-y: auto;
    height: 545px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-category-block-content-norecord-history {
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-category-block-content-norecord-history-length{
    height: 235px;
    overflow-y: auto;
}

.home-category-block-content::-webkit-scrollbar {
    width: 3px;
}

.home-category-block-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
}

.home-category-block-content::-webkit-scrollbar-thumb {
    background: #205e83;
}

.home-filter-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 15px;
}

.home-filter-block select {
    background: #fff;
    border: 1px solid #bdc2c8;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #4f4646;
    width: 100%;
    appearance: none;
    background: url("../downimage.svg") no-repeat 97% center;
}

.home-filter-block .select-test {
    background: #fff;
    border: 1px solid #bdc2c8;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #4f4646;
    width: 100%;
    appearance: none;
    background: url("../downimage.svg") no-repeat 99% center;
}

.home-filter-block select:hover {
    border: 1px solid #205e83;
}

.home-filter-block select:focus-visible {
    outline: none;
}

.home-filter-block-rtl {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 15px;
}

.home-filter-block-rtl select {
    background: #fff;
    border: 1px solid #bdc2c8;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #4f4646;
    width: 100%;
    appearance: none;
    background: url("../downimage.svg") no-repeat -4% center;
}

.home-filter-block-rtl .select-test {
    background: #fff;
    border: 1px solid #bdc2c8;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #4f4646;
    width: 100%;
    appearance: none;
    background: url("../downimage.svg") no-repeat 1% center;
}

.home-filter-block-rtl select:hover {
    border: 1px solid #205e83;
}

.home-filter-block-rtl select:focus-visible {
    outline: none;
}

button.filter {
    background: transparent;
    border: none;
    width: 55px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}

button.filter svg {
    fill: #fff;
    font-size: 20px;
}

.home-lesson-block {
    background-color: #E2E8F0;
    border-radius: 10px;
    padding: 25px;
    height: 730px;
    overflow-x: hidden;
}

.home-lesson-block::-webkit-scrollbar {
    width: 3px;
}

.home-lesson-block::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
}

.home-lesson-block::-webkit-scrollbar-thumb {
    background: #205e83;
}

.home-content-image {
    width: 185px;
    height: 203px;
}

.home-content-image img {
    border-radius: 20px;
    height: 100%;
    object-fit: cover;
    width: 100%;
    border: 0.1px solid gray;
}

.home-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.home-item-content {
    width: calc(100% - 210px);
}

.home-rating-block {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.home-rating-block ul{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 7px;
}

.home-rating-block ul li svg {
    width: 16px;
    height: 16px;
    fill: #F59E0B;
}

.home-rating-block ul li:last-child svg {
    fill: #cfccdf;
}

.home-item-content h3 {
    margin: 0;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-item-content p {
    margin: 0;
    font-size: 16px;
}

.home-item-content > p {
    margin: 10px 0;
    color: rgba(0, 0, 0, 0.60);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home-item-content span {
    color: rgba(0, 0, 0, 0.67);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home-user-block {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
}

.home-user-block img {
    border-radius: 50%;
    border: 1px solid #F0E5FF;
    width: 35px;
    height: 35px;
}

.home-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.home-info-block {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100%;
}

.home-info-block svg {
    fill: #205e83;
    font-size: 18px;
}

.home-info-block p {
    color: rgba(0, 0, 0, 0.60);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home-content h6 {
    flex: 0 0 100%;
}

.home-inner-content {
}

.home-search-block .home-search-block-contant{
    background:#FFFFFF;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09);
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    width: 100%;
    padding:20px;
    z-index: 2;
}

.home-search-block .home-search-btn-container{
    text-align: end;
    margin-top:10px;
}

.home-search-block-contant .home-search-block-contant-recent{
    font-size: 16px;
    font-weight: 600;
}

.home-search-block-contant-recent-first{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-search-block-contant-recent-first .home-search-block-contant-recent-second{
    display:flex;
    align-items:center;
}

.home-search-block-contant-recent-second .home-search-block-contant-recent-second-name{
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    cursor: pointer;
}

.position-relative{
    position: relative;
}

.bg-white{
    background: white;
}

.border-1{
    border-radius: 10px;
}

.m-0{
    margin: 0 !important;
}

.mb-0{
    margin-bottom: 0 !important;
}

.main-title-filter{
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 15px;
}

.main-title-filter-form .main-title-filter-lebel{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}
.search_filter_checkbox_wrap .MuiIconButton-label input{
    min-width: 100%;
}
.main-title-filter-form .MuiFormControlLabel-label{
    font-family: "Corbel V2";
    font-size: 17px;
    font-weight: 400;
}

.no-courses-available{
    font-family: "Corbel V2";
    font-size: 17px;
    font-weight: 400;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.button-green-clear{
    background-color: #205e83 !important;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-family: "Corbel V2";
    padding: 10px 16px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.w-100{
    width: 100% !important;
}

.gap-0{
    gap: 0 !important;
}

.home-inner-content p {
    margin: 0;
    color: rgba(0, 0, 0, 0.67);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.home-inner-content span {
    color: #000;
    cursor: pointer;
    text-decoration: underline;
}

.home-lesson-block h6 {
    font-size: 16px;
    margin: 25px 0 20px;
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}   

.home-lesson {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #94A3B8;
    padding: 15px 25px;
    border-radius: 10px;
    background: #94A3B8;
    color: #625c5c;
    cursor: pointer;
}

.home-lesson-activeted {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #37657F;
    padding: 15px 25px;
    border-radius: 10px;
    background: #94A3B8;
    color: #625c5c;
    cursor: pointer;
}

.home-lesson:not(:last-child) {
    margin: 0 0 20px;
}

.home-lesson-activeted:not(:last-child) {
    margin: 0 0 20px;
}

.home-lesson-content {
    display: flex;
    align-items: center;
    gap: 20px;
}

.home-lesson-content p {
    margin: 0;
    color: rgba(0, 0, 0, 0.67);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home-lesson-content span {
    width: 44px;
    height: 44px;
    background: #205e83;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    min-width: 44px;
    max-width: 44px;
}

.home-lesson-content span svg {
    fill: #fff;
    font-size: 24px;
}

.home-category-block-content .home-category-content {
    margin-right: 15px;
    cursor: pointer;
}

.text-capitalize{
    text-transform: capitalize;
}

.fashion-review-block-length{
    margin-top: 37px;
    border-top: 1px solid rgba(0, 0, 0, 0.20);
    padding-top: 15px;
}

.mr-25{
    margin-right: 25px !important;
}

.m-25{
    margin: 0 25px !important;
}
.language-tag{
  color: rgba(0, 0, 0, 0.60);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width:1366px) {
    .home-page-col-3 {
        width: calc(40% - 15px);
    }

    .home-page-col-9 {
        width: calc(60% - 15px);
    }
    
    .home-item-content {
        width: calc(100% - 210px);
    }
}

@media screen and (max-width:1470px) {
    .m-25 {
        margin-top: 10px !important;
    }
}

@media screen and (max-width: 1199px) {
    .home-page-col-3 {
        flex: 0 0 100%;
    }

    .home-page-col-9 {
        flex: 0 0 100%;
    }

    .home-category-block-content {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
    }
    
    .home-category-content {
        width: calc(50% - 15px);
    }

    .home-category-content:not(:last-child) {
        margin: 0;
    }
}
@media(max-width:1192px){
    
    .home-category-block-content{
        height: auto;
    }
    .home-category-content{
        width: calc(50% - 30px);
    }
}
@media screen and (max-width: 991px) {
    .page-side-nav {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .home-content-image {
       width: 100%;
    }

    .home-item-content {
        width: 100%;
    }

    .home-category-content {
        width: 100%;
    }
}

@media screen and (max-width: 575px) {
    .home-category-content {
        width: 100%;
    }
}
