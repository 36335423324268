.content-wrapper::-webkit-scrollbar {
    width: 5px;
}

.content-wrapper::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    background-color: transparent;
    border-radius: 10px;
}

.content-wrapper::-webkit-scrollbar-thumb {
    background: rgb(32, 94, 131, 0.5);
    border-radius: 10px;
}

.profile-block {
    margin-bottom: 20px;
}

.profile-block h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 15px;
}

.flex-direction-column{
    flex-direction: column;
}

.align-content-start{
    align-content: flex-start;
}

.mt-0{
    margin-top: 0 !important;
}

.mb-05{
    margin-bottom: 5px !important;
}

.dialog-content-body{
    padding: 0 40px !important;
    margin-bottom: 20px;
}

.dialog-content-body h5 {
    margin: 0 0 5px;
    color: #334155;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.12px;
}

.dialog-content-body p {
    margin: 16px 0 14px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #37657F;
}


.profile-info {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    background: #F8FAFC;
    padding: 0px;
    border-radius: 7px;
    border: 1px solid #94A3B8;
}

.profile-info-content h4 {
    margin: 0 0 7px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.176px;
}

.profile-info-content span {
    color: gray;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.154px;
}

.profile-info-image {
    width: 50px;
    height: 50px;
    background: #8d9db170;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.profile-info-image img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.profile-info-content {
    line-height: 1.6;
}

.profile-block-heading {
    padding-top: 25px;
}

.profile-block-heading h3 {
    margin: 0 0 15px;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.198px;
}

.profile-form-block select {
    padding: 12px;
    border-radius: 7px;
    width: 100%;
    border: 1px solid lightgray;
    background-color: #fff;
}

.profile-conetnt .form-row {
    display: flex;
    margin: 0 -15px;
    align-items: baseline;
}

.profile-conetnt .form-group {
    width: 50%;
    margin-bottom: 20px;
    padding: 0 15px;
}

.form-group.w-100 {
    width: 100%;
}

.profile-conetnt .form-row span {
    font-size: 12px;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: block;
}

.upload-btn-wrapper .button {
    background: #fff;
    padding: 12px;
    border-radius: 7px;
    width: 100%;
    border: 1px solid lightgray;
    text-align: left;
    color: gray;
    min-height: 50px;
}

.upload-btn-wrapper input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* .upload-btn-wrapper svg {
    position: absolute;
    right: 12px;
    top: 13px;
    fill: #808080;
} */

.upload-file-btn {
    position: relative;
}

.upload-file {
    position: absolute;
    right: 30px;
    top: -74px;
    width: 40px;
    height: 40px;
    background: #f8f7ff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}

.upload-file .button {
    background: #fff;
    padding: 12px;
    border-radius: 7px;
    width: 100%;
    border: 1px solid #CBD5E1;
    text-align: left;
    color: #37657F;
    min-height: 50px;
}

.upload-file input {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    width: 45px;
    height: 45px;
}

.upload-file svg {
    fill: #808080;
    font-size: 16px;
}

.profile-form-block label {
    display: block;
    margin-bottom: 15px;
    color: #334155;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.profile-form-block input {
    padding: 12px;
    border-radius: 7px;
    width: 100%;
    border: 1px solid #CBD5E1;
    background-color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #37657F;
}

.profile-form-block input:focus-visible {
    outline: none;
}

.profile-block-content, .profile-block-content-password {
    background: #F8FAFC;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid #94A3B8;
}

.profile-button {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 35px;
    flex-wrap: wrap;
}

.profile-button a {
    width: 100%;
    display: block;
    max-width: 150px;
    min-width: 150px;
}

.button-edit, .button-outline-profile-edit {
    padding: 12px !important;
}

.profile-button .btn.button-green, .button-edit {
    max-width: 170px;
    padding: 5px;
}

a.btn.button-green {
    background-color: #205e83;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    min-width: 180px;
}

.btn.button-outline-profile, .button-outline-profile-edit{
    background-color: #F1F5F9;
    color: #205e83;
    max-width: 180px;
    padding: 5px;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    border-radius: 8px;
}

.btn.button-red-outline, .button-red.button-edit:hover {
    background-color: transparent !important;
    color: #DC2626 !important;
    border: 1px solid #DC2626 !important; 
}

.btn.button-red-outline:hover, .button-red.button-edit {
    background-color: #DC2626 !important;
    color: #fff !important;
    border: 1px solid #DC2626 !important; 
}

.btn.button-red-outline svg {
    fill: #DC2626 !important;
    vertical-align: middle;
    margin-left: 5px;
}

.btn.button-red-outline:hover svg {
    fill: #fff !important;
}

.btn.button-outline-profile svg, .button-outline-profile-edit svg {
    fill: #205e83;
}

.btn.button-outline-profile:hover, .button-outline-profile-edit:hover {
    background-color: #205e83;
    color: #fff;
}

.btn.button-outline-profile:hover svg, .button-outline-profile-edit:hover svg {
    fill: #fff;
}

.profile-form-block .w-100 {
    width: 100%;
}

/* Modal css */

.none-modal {
    display: block;
}

.modal-block {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 7;
}

.modal-dialog {
    max-width: 600px;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09);
}

.modal-header {
    text-align: right;
    padding: 40px 40px 16px 40px !important;
}

.modal-header span {
    font-size: 32px;
}

.MuiDialog-paperWidthXs{
    max-width: 520px !important;
}

button.close {
    border: none;
    cursor: pointer;
    padding: 0;
    color: #1C1B1F;
    border-radius: 6px;
    background: rgba(217, 217, 217, 0.50);
    width: 40px;
    height: 40px;
}

.modal-body {
    padding: 0 40px;
    position: relative;
}

.modal-icon {
    background: #F1F5F9;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    margin-bottom: 20px;
}

.modal-icon svg {
    font-size: 18px;
    fill: #37657F;
}

.modal-body h5 {
    margin: 0 0 5px;
    color: #334155;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.12px;
}

.modal-body p {
    margin: 16px 0 25px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #37657F;
}

.modal-footer {
    margin: 0;
    padding: 20px 40px;
}

.modal-footer .btn {
    max-width: 150px;
    min-width: 150px;
    font-size: 16px;
    border-radius: 8px;
}

/* .modal-footer .button-outline-profile-edit {
    max-width: 110px;
    min-width: 110px;
} */

.modal-body:after {
    content: "";
    position: absolute;
    border-bottom: 1px solid #E2E8F0;
    bottom: -25px;
    left: 50%;
    width: 87%;
    transform: translate(-50%);
}

.upload-modal-block .modal-body:after {
    content: "";
    position: absolute;
    border-bottom: 1px solid #E2E8F0;
    bottom: -25px;
    left: 50%;
    width: 100%;
    transform: translate(-50%);
}


/* Upload image modal */
.upload-modal-block {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 7;
}

.upload-modal-block .modal-dialog {
    max-width: 1280px;
    margin: 0 auto;
    background: #fff;
    /* border: 1px solid; */
    border-radius: 10px;
    padding: 40px;
}

.upload-modal-block .modal-body {
    padding: 40px 0;
    position: relative;
}

.upload-modal-block .modal-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 0;
}

.text-white{
    color: #FFFFFF !important;
}

.text-default{
    color: #205e83  !important;
}

.align-items-start{
    align-items: start !important;
}

.f-400{
    font-weight: 400 !important;
}


.MuiCircularProgress-svg{
    margin: 0 !important;
}

.upload-modal-block .block-heading {
    padding: 0;
}

.upload-modal-block .block-heading h3 {
    margin: 0 0 5px;
    color: #334155;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.upload-modal-block .block-heading p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5px;
    margin: 0;
    color: #37657F;
}

.upload-modal-block button.close {
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    width: 40px;
    height: 40px;
    line-height: 35px;
    color: #1C1B1F;
    border-radius: 6px;
    background: rgba(217, 217, 217, 0.50);
}

.upload-modal-block .MuiAlert-standardError {
    margin: 40px 0 0;
    background: #FEE2E2;
    color: #DC2626;
    border-left: 4px solid #DC2626;
    border-radius: 4px;
}

.darg-block {
    border: 1px dashed #94A3B8;
    /* margin: 0; */
    border-radius: 5px;
    height: 180px;
    text-align: center;
    background: #F8FAFC;
    cursor: pointer;
    position: relative;
}

.darg-block .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    height: 180px;
    line-height: 150px;
}

.darg-block .upload-btn-wrapper .button {
    border: none;
    color: #334155;
    background: transparent;
    padding: 0;
    text-align: center;
    height: 180px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.darg-block .upload-btn-wrapper svg {
    font-size: 20px;
    position: absolute;
    left: 50%;
    top: 30px;
    transform: translate(-50%);
    /* fill: #1C1B1F !important; */
    width: 30px;
    height: 30px;
}

.darg-block .upload-btn-wrapper input[type=file] {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    height: 180px;
    /* z-index: 1; */
}

.darg-block .drop-button {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.darg-block .drop-button span {
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #37657F;
    padding: 9px 0 0;
}

.upload-modal-block .btn.button-outline-profile {
    max-width: 100px;
    min-width: 100px;
    border: 1px solid #94A3B8;
}

.upload-modal-block .modal-footer {
    margin: 0;
    padding: 40px 0 0;
    text-align: right;
}

.upload-modal-block .modal-footer .btn {
    padding: 12px;
    /* max-width: 130px;
    min-width: 130px; */
}

.upload-modal-block .image-block {
    position: absolute;
    bottom: 25px;
    right: 35px;
    background: #CBD5E1;
    border-radius: 10px;
    padding: 15px 10px;
}

.upload-modal-block .image-block p {
    margin: 0;
    color: #37657F;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.upload-modal-block .image-block svg {
    vertical-align: middle;
    margin-right: 10px;
    font-size: 18px;
}

   /* process Image */

   .process-info {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    padding: 16px;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0 5px 8px #ddd;
    position: relative;
}

.process-info-content {
    line-height: 24px;
}

.process-block {
    margin-top: 40px;
}

.process-info-content h6 {
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #0F172A;
}

.process-info-content span {
    color: #37657F;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.process-info-image {
    width: 50px;
    height: 50px;
    background: #8d9db170;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.process-info-image svg {
    font-size: 20px;
    fill: #687584;
}

.process-block-close {
    position: absolute;
    right: 20px;
    top: 24px;
    cursor: pointer;
}

.process-block-close svg {
    font-size: 22px;
    fill: #687584;
}

.process-bar-clr {
    background-color: transparent !important;
    height: 2px;
}

.process-bar-clr .MuiLinearProgress-barColorPrimary {
    background-color: #6c7487;
}


@media screen and (max-width: 1366px) {
    .upload-modal-block .modal-dialog {
        max-width: 1000px;
    }
}

@media screen and (max-width: 1199px) {
    .upload-modal-block .modal-dialog {
        max-width: 850px;
    }
}

@media screen and (max-width: 991px) {
    .profile-conetnt .form-row {
        display: block !important;
    }
    .profile-conetnt .form-group {
        width: 100% !important;
    }

    .upload-modal-block .modal-dialog {
        max-width: 650px;
    }
}

@media screen and (max-width: 575px) {

    .btn.button-outline-profile {
        background-color: transparent;
        color: #687584;
        max-width: 100%;
    }

    a.btn.button-green, .btn.button-outline-profile, .button-outline-profile-edit {
        min-width: 100%;
    }

    .upload-modal-block .modal-dialog {
        max-width: 340px;
        padding: 20px;
    }

    .upload-modal-block .block-heading {
        padding: 0;
        max-width: 260px;
        text-align: left;
    }

    .modal-dialog {
        max-width: 350px;
    }

    .upload-modal-block .image-block {
        bottom: 15px;
        right: 20px;
        padding: 10px;
    }

    .upload-modal-block .block-heading h3 {
        font-size: 26px;
    }

    .modal-footer {
        padding: 20px 10px;
        gap: 10px;
    }

    .modal-body {
        border-bottom: 1px solid #E2E8F0;
        padding: 0 25px;
    }

    button.btn.button-green.save-modal-block {
        max-width: 100%;
    }

}
/* ======= new change ======= */
.profile-info-image {
    width: 100px;
    height: 100px;
    border: 1px solid #37657F;
}
.profile-info {
    border: none;
    background: none;
    padding-left: 0;
}

.profile-info .upload-file {
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid #37657F;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 16px;
    margin-bottom: 23px;
    position: static;
    margin-top: 6px;
}

.profile-info .bin-wrapper {
    display: flex;
    align-items: center;
    width: max-content;
    margin-left: 18px;
    cursor: pointer;
    border: none;
    background: transparent;
    font-family: "Corbel V2";
}

.profile-info .bin-wrapper:disabled {
    cursor: not-allowed !important;
}
.mb-1{
    margin-bottom: 10px;
}

.p-0{
    padding: 0 !important;
}

span.c-password {
    position: absolute;
    right: 10px;
    top: 7px;
    fill: #94A3B8;
    font-size: 16px;
}

.rtl-password {
    left: 10px !important;
    right: auto !important;
}

.profile-info .bin-wrapper svg {
    margin-right: 8px;
}
.bin-wrapper span {
    color: #DC2626;
}
.profile-conetnt .profile-block-heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.profile-conetnt .edit-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    cursor: pointer;
}

.profile-conetnt .edit-wrapper span {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #37657F;
}

.profile-conetnt .profile-block-heading h3 {
    margin-right: 32px;
}

.profile-conetnt .edit-wrapper svg {
    margin-right: 8px;
}